import {FC, MouseEventHandler} from 'react'
import {cn} from 'src/app/utils/cn-utils'
import GIcon from '../../Libs/Icon/GIcon'

interface ModalHeaderProps {
  header?: string
  titleSize?: 'sm' | 'md'
  handleClose?: MouseEventHandler
}

const ModalHeader: FC<ModalHeaderProps> = ({header = 'Title', handleClose, titleSize = 'md'}) => {
  return (
    <div className='flex items-center justify-between w-full p-6 font-medium border-b border-[#e9ecef]'>
      <div
        className={cn(
          'flex items-center font-medium',
          {'text-fs-6': titleSize === 'md'},
          {'text-fs-7': titleSize === 'sm'}
        )}
      >
        {header}
      </div>

      {/* begin::Close */}
      <div data-testid='close-icon' onClick={handleClose} className='cursor-pointer text-[#adb5bd]'>
        <GIcon icon='IconClose' className='w-6 h-6' />
      </div>
    </div>
  )
}

export default ModalHeader
