import React from 'react'
import {useLocation} from 'react-router'
import {Link} from 'react-router-dom'
import {useMasterLayout} from 'src/app/context/MasterContext'
import {cn} from 'src/app/utils/cn-utils'
import {checkIsActive} from 'src/app/utils/router-helpers-utils'
import {KTSVG} from '../../Libs/Icon/KTSVG'
import BulletItem from './BulletItem'
import DisabledMenu from './DisabledMenu'

type Props = {
  children?: React.ReactNode
  to: string
  title: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
  activePath?: string
  badge?: string
  badgeCircle?: boolean
  disabled?: boolean
}

const AsideMenuItem: React.FC<Props> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet = false,
  activePath,
  badge,
  badgeCircle,
  disabled = false,
}) => {
  const {pathname} = useLocation()
  const isActive = checkIsActive(pathname, activePath ?? to)
  const {minimize} = useMasterLayout()

  return (
    <div className='relative block p-0 text-fs-9'>
      {disabled && <DisabledMenu />}
      <Link
        className={cn(
          'p-2 m-2 cursor-pointer flex flex-nowrap items-center rounded-lg transition duration-100 font-medium h-10',
          'hover:text-primary-500',
          {'bg-primary-50 text-primary-500': isActive},
          {'text-neutral-900': !isActive},
          {truncate: minimize}
        )}
        to={to}
      >
        {hasBullet && <BulletItem isActive={isActive} />}
        {icon && (
          <span className='flex items-center w-8 mr-2 shrink-0'>
            <KTSVG path={icon} className='w-6 h-6' />
          </span>
        )}
        {/* still bootstrap style / change it !  */}
        {fontIcon && <i className={cn('bi fs-3', fontIcon)}></i>}

        <span
          className={cn('flex items-center flex-1 transition duration-300', {
            'opacity-0 group-hover:opacity-100': minimize,
          })}
        >
          {title}
        </span>

        {badge ? (
          <span
            className={cn('badge badge-light-secondary', {'badge-circle': badgeCircle})}
            style={{minWidth: 23, minHeight: 23}}
          >
            {badge}
          </span>
        ) : null}
      </Link>
      {children}
    </div>
  )
}

export {AsideMenuItem}
