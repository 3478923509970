import {defaultBreadcrumbs, ScreenProp} from 'src/app/models/screen-prop'

export const CategoryManagementScreen: Record<string, ScreenProp> = {
  CATEGORY_MANAGEMENT: {
    PATH: '/master/category-management',
    TITLE: 'Category Management',
    TITLE_ALT: 'Category Management List',
    PERMISSION: 'service_category.read',
    breadcrumbs: defaultBreadcrumbs,
  },
  CATEGORY_MANAGEMENT_ADD: {
    PATH: '/master/category-management/add',
    TITLE: 'Add Category Management',
    TITLE_ALT: 'Add Category Management',
    PERMISSION: 'service_category.create',
    breadcrumbs: defaultBreadcrumbs,
  },
  CATEGORY_MANAGEMENT_EDIT: {
    PATH: '/master/category-management/edit/:id',
    TITLE: 'Edit Category Management ',
    TITLE_ALT: 'Edit Category Management',
    PERMISSION: 'service_category.update',
    breadcrumbs: defaultBreadcrumbs,
  },
}
