import {defaultBreadcrumbs, ScreenProp} from 'src/app/models/screen-prop'

export const TaskListScreen: Record<string, ScreenProp> = {
  TASK_LIST: {
    PATH: '/task-management/task-list',
    TITLE: 'Task List',
    TITLE_ALT: 'Task List',
    PERMISSION: 'task.read',
    breadcrumbs: defaultBreadcrumbs,
  },
  TASK_LIST_ADD: {
    PATH: '/task-management/task-list/add',
    TITLE: 'Add Task',
    TITLE_ALT: 'Add Task',
    PERMISSION: 'task.create',
    breadcrumbs: defaultBreadcrumbs,
  },
  TASK_LIST_EDIT: {
    PATH: '/task-management/task-list/edit/:id',
    TITLE: 'Edit Task ',
    TITLE_ALT: 'Edit Task',
    PERMISSION: 'task.update',
    breadcrumbs: defaultBreadcrumbs,
  },
  TASK_LIST_DETAIL: {
    PATH: '/task-management/task-list/detail/:id',
    TITLE: 'Detail Task',
    TITLE_ALT: 'Detail Task',
    PERMISSION: 'task.read',
    breadcrumbs: defaultBreadcrumbs,
  },
  TASK_LIST_FOLLOW: {
    PATH: '/task-management/task-list/follow-up/:id',
    TITLE: 'Follow Up Task ',
    TITLE_ALT: 'Follow Up Task',
    PERMISSION: 'task.create',
    breadcrumbs: defaultBreadcrumbs,
  },
}
