import React, {Children, useState} from 'react'
import {useLocation} from 'react-router'
import {useMasterLayout} from 'src/app/context/MasterContext'
import {cn} from 'src/app/utils/cn-utils'
import {checkIsActive} from 'src/app/utils/router-helpers-utils'
import {KTSVG} from '../../Libs/Icon/KTSVG'
import BulletItem from './BulletItem'
import DisabledMenu from './DisabledMenu'

type Props = {
  children?: any
  to: string
  title: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
  activePath?: string
  disabled?: boolean
}

const AsideMenuItemWithSub: React.FC<Props> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet,
  disabled = false,
}) => {
  const {pathname} = useLocation()
  const isActive = checkIsActive(pathname, to)
  const {minimize} = useMasterLayout()
  const splitPathname = '/' + pathname.split('/')[1]
  const [open, setOpen] = useState<string>(splitPathname)

  const handleOpen = (value: string) => {
    setOpen(open === value ? '' : value)
  }

  const allChildren = Children.toArray(children)

  let countArray = allChildren.length
  let newMaxHeight = `max-h-[${3 * countArray + 3}rem]`

  allChildren.forEach((child: any) => {
    const {props} = child

    if (props.menu?.children !== undefined) {
      const menuLength = props.menu.children.length
      countArray += menuLength
    }
  })

  return (
    <div className='relative'>
      {disabled && <DisabledMenu />}

      <div
        className={cn('p-0 block overflow-hidden text-fs-9 relative', {
          'overflow-visible': to,
        })}
        onClick={() => handleOpen(to)}
      >
        <span
          className={cn(
            'bg-none p-2 m-2 cursor-pointer flex items-center transition duration-100 font-medium h-10',
            'hover:text-primary-500',
            {
              // 'text-primary-500': open,
              'text-neutral-900': !open,
            }
          )}
        >
          {hasBullet && <BulletItem isActive={isActive} />}
          {icon && (
            <span className='flex items-center w-8 mr-2 shrink-0'>
              <KTSVG path={icon} className='w-6 h-6' />
            </span>
          )}
          {/* still bootstrap style / change it !  */}
          {fontIcon && <i className={cn('bi fs-3', fontIcon)}></i>}
          <span
            className={cn('truncate flex items-center flex-1 transition-all duration-300', {
              'opacity-0 group-hover:opacity-100': minimize,
            })}
          >
            {title}
          </span>
          <span
            className={cn(
              'flex items-stretch relative overflow-hidden ml-2 w-[0.8rem] h-[0.8rem] transition ease-in',
              'after:block after:content-[""] after:w-full after:bg-[url("/public/media/icons/IconChevronRight.svg")] after:bg-center after:bg-no-repeat',
              {'rotate-90': open === to}
            )}
          ></span>
        </span>
        <div
          onClick={(e) => e.stopPropagation()}
          className={cn(
            `rounded-lg transition-all overflow-hidden duration-300 ease-in ${
              open === to && newMaxHeight
            }`,
            {'max-h-0 ': open !== to},
            {'ml-0 mr-0 pl-0': minimize}
          )}
        >
          {children}
        </div>
      </div>
    </div>
  )
}

export {AsideMenuItemWithSub}
