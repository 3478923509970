import React, {FC, useEffect, useRef} from 'react'
import {useLocation} from 'react-router'
import {MasterProvider} from 'src/app/context/MasterContext'
import {PageDataProvider} from 'src/app/context/PageDataContext'
import {HeaderToastProvider} from 'src/app/context/ToastContext'
import {HeaderToast} from '../Libs'
import ContentWrapper from './ContentWrapper'
import ScrollToTop from './ScrollToTop'
import TooltipView from './TooltipView'
import AsideDefault from './aside/AsideDefault'
import {Footer} from './footer/Footer'
import HeaderWrapper from './header/HeaderWrapper'
import OverlayWrapper from './overlay/OverlayWrapper'

const MasterLayout: FC<{children?: React.ReactNode}> = ({children}) => {
  const scrollRef = useRef<HTMLDivElement | null>(null)
  const {pathname} = useLocation()

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = 0
      window.scrollTo(0, 0)
    }
  }, [pathname])

  return (
    <MasterProvider>
      <PageDataProvider>
        <HeaderToastProvider>
          <OverlayWrapper />

          <div className='pt-[80px] flex flex-row flex-[1_0_auto] bg-neutral-100 dark:bg-inverse-primary transition-all duration-300'>
            <AsideDefault />

            <ContentWrapper>
              <HeaderWrapper />
              <HeaderToast />

              <div ref={scrollRef} className='py-6 flex flex-col flex-[1_0_auto]'>
                <div className='flex flex-[1_0_auto]'>
                  <div className='w-full px-8 mx-auto'>{children}</div>
                </div>
              </div>

              <Footer />
            </ContentWrapper>
          </div>
        </HeaderToastProvider>
      </PageDataProvider>

      <ScrollToTop />
      <TooltipView />
    </MasterProvider>
  )
}

export default MasterLayout
