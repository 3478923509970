import {cva} from 'class-variance-authority'

export const alertTheme = cva(['flex', 'items-start', 'rounded', 'py-2', 'px-3'], {
  variants: {
    colors: {
      primary: ['text-primary-500', 'bg-primary-50'],
      success: ['text-success-500', 'bg-success-50'],
      danger: ['text-danger-500', 'bg-danger-50'],
      warning: ['text-warning-500', 'bg-warning-50'],
      info: ['text-info-500', 'bg-info-50'],
    },
  },
  compoundVariants: [
    {
      colors: 'danger',
    },
  ],
  defaultVariants: {
    colors: 'danger',
  },
})
