import {ScreenProp} from 'src/app/models/screen-prop'
import {CompanyListScreen} from './page/company-list/Screen'
import {DepartmentListScreen} from './page/department-list/Screen'
import {OrganizationScreen} from './page/organization/Screen'
import {RoleScreen} from './page/role/Screen'
import {UserScreen} from './page/user/Screen'

const AdministrationScreens: Record<string, ScreenProp> = {
  ADMINISTRATION: {
    PATH: '/administration',
    TITLE: 'Administration',
    TITLE_ALT: 'Administration',
  },

  ...CompanyListScreen,
  ...DepartmentListScreen,
  ...OrganizationScreen,
  ...RoleScreen,
  ...UserScreen,
}

export default AdministrationScreens
