import {FC} from 'react'
import en from 'react-date-object/locales/gregorian_en'
import DatePicker from 'react-multi-date-picker'
import TimePicker from 'react-multi-date-picker/plugins/time_picker'
import {FormText, FormTextProps} from '../Text'
import {FormTimeProps} from './Timepicker.types'

const CustomInput: FC<FormTextProps> = ({
  onFocus,
  className,
  value,
  placeholder,
  error,
  touched,
  disabled,
}) => {
  return (
    <FormText
      onFocus={onFocus}
      value={value}
      appendInnerIcon='IconCalendar'
      clickAppendInnerIcon={onFocus as () => void}
      appendInnerClassname='text-[#dee2e6]'
      readOnly
      placeholder={placeholder}
      error={error}
      touched={touched}
      className={className}
      disabled={disabled}
    />
  )
}

export const FormTimepicker: FC<FormTimeProps> = ({
  placeholder = 'Enter date',
  error,
  touched,
  className,
  disabled,
  ...rest
}) => {
  return (
    <DatePicker
      portal
      containerStyle={{
        width: '100%',
      }}
      render={
        <CustomInput
          placeholder={placeholder}
          error={error}
          touched={touched}
          inputClassName={className}
          disabled={disabled}
        />
      }
      format={'HH:mm'}
      plugins={[<TimePicker hideSeconds key={'time'} />]}
      disableDayPicker
      className='custom-calendar'
      locale={en}
      {...rest}
    />
  )
}
