import {defaultBreadcrumbs, ScreenProp} from 'src/app/models/screen-prop'

export const OrganizationScreen: Record<string, ScreenProp> = {
  ORGANIZATION: {
    PATH: '/administration/setup-organization',
    TITLE: 'Organization List',
    TITLE_ALT: 'Setup Organization',
    breadcrumbs: defaultBreadcrumbs,
  },
  ORGANIZATION_ADD: {
    PATH: '/administration/setup-organization/add',
    TITLE: 'Add Organization',
    TITLE_ALT: 'Add Organization',
    breadcrumbs: defaultBreadcrumbs,
  },
  ORGANIZATION_EDIT: {
    PATH: '/administration/setup-organization/edit/:id',
    TITLE: 'Edit Organization ',
    TITLE_ALT: 'Edit Organization',
    breadcrumbs: defaultBreadcrumbs,
  },
  ORGANIZATION_DETAIL: {
    PATH: '/administration/setup-organization/detail/:id',
    TITLE: 'Detail Organization',
    TITLE_ALT: 'Detail Organization',
    breadcrumbs: defaultBreadcrumbs,
  },
}
