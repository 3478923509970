import {FC, MouseEventHandler, ReactNode} from 'react'
import {GModal} from '../../Libs'
import ModalFooter from './ModalFooter'
import ModalHeader from './ModalHeader'

interface BasicModalProps {
  show: boolean
  handleSuccess: MouseEventHandler
  handleClose: MouseEventHandler
  header: string
  children?: ReactNode
  positiveLabel?: string
  negativeLabel?: string
  size?: 'xl' | 'lg' | 'md' | 'sm'
  loading?: boolean
  positiveColor?: 'primary' | 'success' | 'warning' | 'danger' | 'info'
}

const BasicModal: FC<BasicModalProps> = ({
  show,
  handleSuccess,
  handleClose,
  header,
  positiveLabel,
  negativeLabel,
  size = 'md',
  loading,
  children,
  positiveColor = 'primary',
}) => {
  return (
    <GModal show={show} onHide={handleClose} size={size}>
      <ModalHeader header={header} handleClose={handleClose} />
      <div className='px-6 py-5 min-h-[100px] text-[#495057] w-full'>{children}</div>
      <ModalFooter
        handleClose={handleClose}
        handleSuccess={handleSuccess}
        positiveLabel={positiveLabel}
        negativeLabel={negativeLabel}
        loading={loading}
        positiveColor={positiveColor}
      />
    </GModal>
  )
}

export default BasicModal
