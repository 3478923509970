import {defaultBreadcrumbs, ScreenProp} from 'src/app/models/screen-prop'

export const AgentListScreen: Record<string, ScreenProp> = {
  AGENT_LIST: {
    PATH: '/agent-management/agent-list',
    TITLE: 'Agent List',
    TITLE_ALT: 'Agent List',
    PERMISSION: 'agent.read',
    breadcrumbs: defaultBreadcrumbs,
  },
  AGENT_LIST_ADD: {
    PATH: '/agent-management/agent-list/add',
    TITLE: 'Add Agent',
    TITLE_ALT: 'Add Agent',
    PERMISSION: 'agent.create',
    breadcrumbs: defaultBreadcrumbs,
  },
  AGENT_LIST_EDIT: {
    PATH: '/agent-management/agent-list/edit/:id',
    TITLE: 'Edit Agent ',
    TITLE_ALT: 'Edit Agent',
    PERMISSION: 'agent.update',
    breadcrumbs: defaultBreadcrumbs,
  },
  AGENT_LIST_DETAIL: {
    PATH: '/agent-management/agent-list/detail/:id',
    TITLE: 'Detail Agent',
    TITLE_ALT: 'Detail Agent',
    PERMISSION: 'agent.read',
    breadcrumbs: defaultBreadcrumbs,
  },
}
