import {defaultBreadcrumbs} from 'src/app/models/screen-prop'

export const OutgoingScreen = {
  OUTGOING: {
    PATH: '/wo-request/outgoing',
    TITLE: 'Outgoing',
    TITLE_ALT: 'Outgoing List',
    breadcrumbs: defaultBreadcrumbs,
  },
  OUTGOING_ADD: {
    PATH: '/wo-request/outgoing/add',
    TITLE: 'Add Outgoing',
    TITLE_ALT: 'Add Outgoing',
    breadcrumbs: defaultBreadcrumbs,
  },
  OUTGOING_EDIT: {
    PATH: '/wo-request/outgoing/edit/:id',
    TITLE: 'Edit Outgoing ',
    TITLE_ALT: 'Edit Outgoing',
    breadcrumbs: defaultBreadcrumbs,
  },
  OUTGOING_DETAIL: {
    PATH: '/wo-request/outgoing/detail/:id',
    TITLE: 'Detail Outgoing',
    TITLE_ALT: 'Detail Outgoing',
    breadcrumbs: defaultBreadcrumbs,
  },
}
