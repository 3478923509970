import clsx from 'clsx'
import {components, ControlProps, GroupBase} from 'react-select'

const Control = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>({
  children,
  isFocused,
  isDisabled,
  ...rest
}: ControlProps<Option, IsMulti, Group>) => (
  <components.Control
    className={clsx('shadow-none min-h-[44px]', {
      focus: isFocused,
      disabled: isDisabled,
    })}
    isFocused
    isDisabled
    {...rest}
  >
    {children}
  </components.Control>
)

const IndicatorSeparator = () => null

// const Option = (props: any) => (
//   <components.Option {...props}>
//     <div className='flex items-center'>
//       <FormCheckbox
//         variant='classic'
//         checked={props.isSelected}
//         className='mr-4'
//         height='min-h-0'
//       />
//       {props.label}
//     </div>
//   </components.Option>
// )

const NoOptionsMessage = (props: any) => {
  return (
    <components.NoOptionsMessage {...props}>
      <div className='font-medium text-start'>Result not found</div>
    </components.NoOptionsMessage>
  )
}

export interface LabelValueProps {
  label?: string
  value?: string
}

export interface IdNameProps {
  id?: string
  name?: string
}

export const CustomReactSelect = {
  Control,
  IndicatorSeparator,
  NoOptionsMessage,
}

export const CustomReactMultiSelect = {
  Control,
  // Option,
  IndicatorSeparator,
  NoOptionsMessage,
}
