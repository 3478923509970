import {FC, MouseEventHandler} from 'react'
import {GButton} from '../../Libs/Button/Button.component'

interface ModalFooterProps {
  handleSuccess?: MouseEventHandler
  handleClose?: MouseEventHandler
  positiveLabel?: string
  negativeLabel?: string
  loading?: boolean
  positiveColor?: 'primary' | 'success' | 'warning' | 'danger' | 'info'
}

const ModalFooter: FC<ModalFooterProps> = ({
  handleSuccess,
  handleClose,
  positiveLabel,
  negativeLabel,
  loading,
  positiveColor = 'success',
}) => {
  return (
    <div className='flex items-center w-full px-6 py-5 border-t border-[#e9ecef] justify-end'>
      <GButton
        onClick={handleClose}
        type='button'
        variant='OUTLINED'
        className='mr-4 min-w-[138px]'
        disabled={loading}
        size={'large'}
      >
        {negativeLabel ?? 'No'}
      </GButton>

      <GButton
        onClick={handleSuccess}
        className='min-w-[138px]'
        type='button'
        loading={loading}
        disabled={loading}
        size={'large'}
        colors={positiveColor}
      >
        {positiveLabel ?? 'Yes'}
      </GButton>
    </div>
  )
}

export default ModalFooter
