import {IncomingScreen} from './page/incoming/Screen'
import {OutgoingScreen} from './page/outgoing/Screen'

const WoRequestScreens = {
  WO_REQUEST: {
    PATH: '/wo-request',
    TITLE: 'WO Request',
    TITLE_ALT: 'WO Request',
  },

  ...IncomingScreen,
  ...OutgoingScreen,
}

export default WoRequestScreens
