import {useMutation} from '@tanstack/react-query'
import {useFormik} from 'formik'
import {FC, useEffect, useState} from 'react'
import {useHistory, useLocation} from 'react-router-dom'
import {FormLabel, FormPassword, GButton} from 'src/app/components/Libs'
import {useHeaderToast} from 'src/app/context/ToastContext'
import {useErrorQuery} from 'src/app/hooks/error-query-hook'
import {useWebTitle} from 'src/app/hooks/title-hook'
import * as Yup from 'yup'
import AuthScreens from '../../Screens'
import {resetPasswordUser, verificationUserToken} from '../../services/Auth.services'

const resetSchema = Yup.object().shape({
  password: Yup.string()
    .max(15, 'Password must be at most 15 characters')
    // .matches(/^(?=.*[A-Z])(?=.*[!@#$&*%^()_-])(?=.*[a-z])(?=.*\d).{8,}$/, () => (
    .matches(/^(?=.*[!@#$&*%^()_-])(?=.*[a-z])(?=.*\d).{8,}$/, () => (
      <>
        Password must be 8 or more characters and contain at least 1 number and 1 special character
      </>
    ))
    .required('Enter your password'),
  new_password: Yup.string()
    .required('Enter your confirmation password')
    .test('equal', 'Your password and confirmation password must match', function (v) {
      // Don't use arrow functions
      const ref = Yup.ref('password')
      return v === this.resolve(ref)
    }),
})

const initialValues = {
  password: '',
  new_password: '',
}

export const CreateNewPassword: FC = () => {
  const history = useHistory()
  let query = new URLSearchParams(useLocation().search)
  const token = query.get('t') as string
  useWebTitle('Create New Password')
  const errorState = useErrorQuery()
  const {addPageToasts} = useHeaderToast()

  const [generatedToken, setGeneratedToken] = useState<string>('')

  const submitFn = useMutation({
    mutationFn: (payload: typeof initialValues) => {
      const val = {
        password: payload.new_password,
      }

      return resetPasswordUser(val, {Authorization: `Bearer ${generatedToken}`})
    },
    onSuccess: () => {
      addPageToasts({scheme: 'success', text: 'Password successfully changed'})
      formik.setSubmitting(false)
      history.replace(AuthScreens.LOGIN_EMAIL.PATH)
    },
    onError: (e) => errorState.errorTemp(e),
  })

  const formik = useFormik({
    initialValues,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: resetSchema,
    onSubmit: (values) => submitFn.mutate(values),
  })

  useEffect(() => {
    const payload = {
      token: token,
    }

    if (token) {
      verificationUserToken(payload)
        .then((res) => {
          const data = res?.data?.response_output?.detail?.token
          if (data === null) {
            history.replace(AuthScreens.LOGIN_EMAIL.PATH)
          }
          setGeneratedToken(data)
        })
        .catch(() => {
          history.replace(AuthScreens.LOGIN_EMAIL.PATH)
        })
    }
  }, [token, history])

  useEffect(() => {
    if (!token) history.replace(AuthScreens.LOGIN_EMAIL.PATH)
  }, [history, token])

  if (!token) return null

  return (
    <div data-testid='create-new-password-page' className='w-full'>
      <div className='mb-14'>
        <div className='mb-3 font-semibold text-fs-5 text-neutral-900'>Create New Password</div>
        <div className='text-neutral-600'>Create a new password to login to your account</div>
      </div>

      <form className='w-full' onSubmit={formik.handleSubmit} noValidate>
        <div className='mb-8'>
          <FormLabel className='mb-2'>Password</FormLabel>
          <FormPassword
            {...formik.getFieldProps('password')}
            name='password'
            placeholder='New Password'
            maxLength={50}
            error={formik.errors.password}
            touched={formik.touched.password}
          />
        </div>

        <div className='mb-8'>
          <FormLabel className='mb-2'>Confirm Password</FormLabel>
          <FormPassword
            {...formik.getFieldProps('new_password')}
            name='new_password'
            placeholder='Confirm New Password'
            maxLength={50}
            error={formik.errors.new_password}
            touched={formik.touched.new_password}
          />
        </div>

        <GButton type='submit' size='large' className='w-full' loading={submitFn.isLoading}>
          Update Password
        </GButton>
      </form>
    </div>
  )
}
