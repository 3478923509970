import {useMemo} from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import {useMasterLayout} from 'src/app/context/MasterContext'
import AdministrationScreens from 'src/app/modules/administration/AdministrationScreen'
import {ModulePermissionQuery} from 'src/app/modules/administration/models/Role.model'
import {IUser} from 'src/app/modules/administration/models/User.model'
import AgentManagementScreens from 'src/app/modules/agent-management/AgentManagementScreen'
import DashboardScreens from 'src/app/modules/dashboard/Screens'
import FormManagementScreens from 'src/app/modules/form-management/FormManagementScreen'
import MasterScreens from 'src/app/modules/master/MasterScreen'
import TaskManagementScreens from 'src/app/modules/task-management/TaskManagementScreen'
import WoRequestScreens from 'src/app/modules/wo-request/WoRequestScreen'
import {cn} from 'src/app/utils/cn-utils'
import {RootState} from 'src/setup'
import {AsideMenuItem} from './AsideMenuItem'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'

export interface Menu {
  id?: string
  to?: string
  title: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
  children?: Menu[]
  module_permissions?: ModulePermissionQuery
  activePath?: string
  badge?: string
  badgeCircle?: boolean
  hidden?: boolean
  disabled?: boolean
}

const useMenus = (user?: IUser): Menu[] => {
  return useMemo(
    () =>
      [
        {
          children: [
            {
              to: DashboardScreens.DASHBOARD.PATH,
              icon: '/media/icons/IconDashboard.svg',
              title: DashboardScreens.DASHBOARD.TITLE,
              hidden: user?.role?.user_type === 'SUPERADMIN',
            },
            {
              to: TaskManagementScreens.TASK_MANAGEMENT.PATH,
              icon: '/media/icons/IconTaskManagement.svg',
              title: TaskManagementScreens.TASK_MANAGEMENT.TITLE,
              hidden: user?.role?.user_type === 'SUPERADMIN',
              children: [
                {
                  to: TaskManagementScreens.TASK_LIST.PATH,
                  activePath: TaskManagementScreens.TASK_LIST.PATH,
                  title: TaskManagementScreens.TASK_LIST.TITLE,
                  hasBullet: true,
                  hidden: user?.role?.user_type === 'SUPERADMIN',
                },
              ],
            },
            {
              to: WoRequestScreens.WO_REQUEST.PATH,
              icon: '/media/icons/IconWoRequest.svg',
              title: WoRequestScreens.WO_REQUEST.TITLE,
              hidden: user?.role?.user_type === 'SUPERADMIN',
              children: [
                {
                  to: WoRequestScreens.INCOMING.PATH,
                  activePath: WoRequestScreens.INCOMING.PATH,
                  title: WoRequestScreens.INCOMING.TITLE,
                  hasBullet: true,
                  hidden:
                    user?.role?.user_type === 'SUPERADMIN' || user?.role?.user_type === 'USER',
                },
                {
                  to: WoRequestScreens.OUTGOING.PATH,
                  activePath: WoRequestScreens.OUTGOING.PATH,
                  title: WoRequestScreens.OUTGOING.TITLE,
                  hasBullet: true,
                  hidden:
                    user?.role?.user_type === 'SUPERADMIN' ||
                    user?.role?.user_type === 'DEPARTMENT',
                },
              ],
            },
            {
              to: FormManagementScreens.FORM_MANAGEMENT.PATH,
              icon: '/media/icons/IconFormManagement.svg',
              title: FormManagementScreens.FORM_MANAGEMENT.TITLE,
              hidden: user?.role?.user_type === 'SUPERADMIN' || user?.role?.user_type === 'USER',
              children: [
                {
                  to: FormManagementScreens.DYNAMIC_FORM.PATH,
                  activePath: FormManagementScreens.DYNAMIC_FORM.PATH,
                  title: FormManagementScreens.DYNAMIC_FORM.TITLE,
                  hasBullet: true,
                  hidden:
                    user?.role?.user_type === 'SUPERADMIN' || user?.role?.user_type === 'USER',
                },
              ],
            },
            {
              to: AgentManagementScreens.AGENT_MANAGEMENT.PATH,
              icon: '/media/icons/IconAgentManagement.svg',
              title: AgentManagementScreens.AGENT_MANAGEMENT.TITLE,
              hidden: user?.role?.user_type === 'SUPERADMIN',
              children: [
                {
                  to: AgentManagementScreens.AGENT_LIST.PATH,
                  activePath: AgentManagementScreens.AGENT_LIST.PATH,
                  title: AgentManagementScreens.AGENT_LIST.TITLE,
                  hasBullet: true,
                  hidden: user?.role?.user_type === 'SUPERADMIN',
                },
              ],
            },
            {
              to: MasterScreens.MASTER.PATH,
              icon: '/media/icons/IconMaster.svg',
              title: MasterScreens.MASTER.TITLE,
              hidden: user?.role?.user_type === 'SUPERADMIN' || user?.role?.user_type === 'USER',
              children: [
                {
                  to: MasterScreens.CATEGORY_MANAGEMENT.PATH,
                  activePath: MasterScreens.CATEGORY_MANAGEMENT.PATH,
                  title: MasterScreens.CATEGORY_MANAGEMENT.TITLE,
                  hasBullet: true,
                  hidden:
                    user?.role?.user_type === 'SUPERADMIN' || user?.role?.user_type === 'USER',
                },
                {
                  to: MasterScreens.SERVICE_MANAGEMENT.PATH,
                  activePath: MasterScreens.SERVICE_MANAGEMENT.PATH,
                  title: MasterScreens.SERVICE_MANAGEMENT.TITLE,
                  hasBullet: true,
                  hidden:
                    user?.role?.user_type === 'SUPERADMIN' || user?.role?.user_type === 'USER',
                },
              ],
            },
            {
              to: AdministrationScreens.ADMINISTRATION.PATH,
              icon: '/media/icons/IconAdministration.svg',
              title: AdministrationScreens.ADMINISTRATION.TITLE,
              hidden: user?.role?.user_type === 'USER',
              children: [
                {
                  to: AdministrationScreens.COMPANY_LIST.PATH,
                  activePath: AdministrationScreens.COMPANY_LIST.PATH,
                  title: AdministrationScreens.COMPANY_LIST.TITLE_ALT,
                  hasBullet: true,
                  hidden: user?.role?.user_type !== 'ORGANIZATION',
                },
                {
                  to: AdministrationScreens.DEPARTMENT_LIST.PATH,
                  activePath: AdministrationScreens.DEPARTMENT_LIST.PATH,
                  title: AdministrationScreens.DEPARTMENT_LIST.TITLE_ALT,
                  hasBullet: true,
                  hidden: user?.role?.user_type !== 'ORGANIZATION',
                },
                {
                  to: AdministrationScreens.ORGANIZATION.PATH,
                  activePath: AdministrationScreens.ORGANIZATION.PATH,
                  title: AdministrationScreens.ORGANIZATION.TITLE_ALT,
                  hasBullet: true,
                  hidden: user?.role?.user_type !== 'SUPERADMIN',
                },
                {
                  to: AdministrationScreens.ROLE.PATH,
                  activePath: AdministrationScreens.ROLE.PATH,
                  title: AdministrationScreens.ROLE.TITLE_ALT,
                  hasBullet: true,
                  hidden: user?.role?.user_type === 'USER',
                },
                {
                  to: AdministrationScreens.USER.PATH,
                  activePath: AdministrationScreens.USER.PATH,
                  title: AdministrationScreens.USER.TITLE_ALT,
                  hasBullet: true,
                  hidden: user?.role?.user_type === 'USER',
                },
              ],
            },
            {
              to: '/setting',
              icon: '/media/icons/IconSetting.svg',
              title: 'Setting',
            },
          ],
        },
      ] as Menu[],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user]
  )
}

export const GeneratedMenu: React.FC<{menu: Menu}> = ({menu}) => {
  const {minimize} = useMasterLayout()

  if (!menu.to) {
    if (!menu.children || menu.children.length === 0) return null
    return (
      <>
        <div className='block p-0'>
          <div className='px-6 pt-8 pb-2'>
            <span
              className={cn('tracking-widest text-neutral-900 uppercase text-fs-10', {
                hidden: minimize,
              })}
            >
              {menu.title}
            </span>
          </div>
        </div>
        {menu.children?.map((child) => {
          return <GeneratedMenu menu={child} key={child.id} />
        })}
      </>
    )
  }
  if (!menu.children)
    return (
      <AsideMenuItem
        to={menu.to}
        icon={menu.icon}
        title={menu.title}
        fontIcon={menu.fontIcon}
        hasBullet={menu.hasBullet}
        activePath={menu.activePath}
        badge={menu.badge}
        badgeCircle={menu.badgeCircle}
        disabled={menu.disabled}
      />
    )

  return (
    <AsideMenuItemWithSub
      to={menu.to}
      title={menu.title}
      fontIcon={menu.fontIcon}
      icon={menu.icon}
      activePath={menu.activePath}
      hasBullet={menu.hasBullet}
      disabled={menu.disabled}
    >
      {menu.children.map((child) => {
        return <GeneratedMenu menu={child} key={child.id} />
      })}
    </AsideMenuItemWithSub>
  )
}

const filterMenus = (
  menus: Menu[] | undefined,
  predicate: (menu: Menu) => boolean
): Menu[] | undefined => {
  const result = menus?.map((menu, index) => ({
    ...menu,
    id: String(index),
    children: filterMenus(menu.children, predicate),
  }))
  return result?.filter((menu) => (!menu.children || menu.children.length > 0) && predicate(menu))
}

const AsideMenuData: React.FC = () => {
  const user: IUser = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as IUser
  const menus = useMenus(user)
  // const {hasAccess} = usePermissions()

  // const generated = useMemo(
  //   () =>
  //     filterMenus(menus, (menu) => hasAccess(menu?.module_permissions) && !Boolean(menu.hidden)),
  //   [menus, hasAccess]
  // )

  const generated = useMemo(() => filterMenus(menus, (menu) => !Boolean(menu.hidden)), [menus])
  return (
    <>
      {generated?.map((child) => {
        return <GeneratedMenu menu={child} key={child.id} />
      })}
    </>
  )
}

export default AsideMenuData
