import {cva} from 'class-variance-authority'

export const toastTheme = cva(
  [
    'transition-opacity',
    'w-full',
    'block',
    'text-fs-9',
    'rounded-lg',
    'mb-4',
    'opacity-0',
    'duration-500',
    'border',
  ],
  {
    variants: {
      scheme: {
        primary: ['bg-primary-100', 'text-primary-500', 'border-primary-500'],
        success: ['bg-success-100', 'text-success-500', 'border-success-500'],
        danger: ['bg-danger-100', 'text-danger-500', 'border-danger-500'],
        warning: ['bg-warning-100', 'text-warning-500', 'border-warning-500'],
        info: ['bg-info-100', 'text-info-500', 'border-info-500'],
      },
    },
  }
)
