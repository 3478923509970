import {FC} from 'react'
import {Link} from 'react-router-dom'
import {usePageData} from 'src/app/context/PageDataContext'
import {cn} from 'src/app/utils/cn-utils'
import {trimBreadcrumbs} from './Breadcrumbs.utils'

const Separator: FC = () => {
  return <span className='inline-block w-1 h-1 rounded-full shrink-0 bg-[#868e96]'></span>
}

export const GBreadcrumbs: FC = () => {
  const {pageTitle, pageDescription, pageBreadcrumbs} = usePageData()

  return (
    <div>
      {(pageDescription || (pageBreadcrumbs && pageBreadcrumbs.length > 0)) && (
        <ul className='flex flex-wrap items-center p-0 m-0 list-none text-fs-10'>
          {Array.from(pageBreadcrumbs ?? []).map((item) => (
            <li
              className={cn('flex items-center pr-2', {
                'text-[#495057] dark:text-[#e9ecef]': !item.isSeparator && item.isActive,
                'text-[#868e96] dark:text-[#f1f3f5]': !item.isSeparator && !item.isActive,
              })}
              key={`${item.path}`}
            >
              {!item.isSeparator ? (
                <Link
                  className='transition-all duration-200 text-[#868e96] hover:text-[#495057]'
                  to={item.path}
                >
                  {trimBreadcrumbs(item.title)}
                </Link>
              ) : (
                <Separator />
              )}
            </li>
          ))}
          <li className='flex items-center text-[#495057] dark:text-[#f1f3f5]'>
            {trimBreadcrumbs(pageDescription ?? pageTitle)}
          </li>
        </ul>
      )}
    </div>
  )
}
