import {FC} from 'react'
import {Tooltip} from 'react-tooltip'

const TooltipView: FC = () => {
  return (
    <>
      <Tooltip id='view-tooltip' className='tooltip-custom'>
        Details
      </Tooltip>
      <Tooltip id='edit-tooltip' className='tooltip-custom'>
        Update
      </Tooltip>
      <Tooltip id='delete-tooltip' className='tooltip-custom'>
        Delete
      </Tooltip>
      <Tooltip id='send-tooltip' className='tooltip-custom'>
        Send
      </Tooltip>
      <Tooltip id='duplicate-tooltip' className='tooltip-custom'>
        Duplicate
      </Tooltip>
      <Tooltip id='reassign-tooltip' className='tooltip-custom'>
        Reassign
      </Tooltip>
      <Tooltip id='reschedule-tooltip' className='tooltip-custom'>
        Reschedule
      </Tooltip>
      <Tooltip id='refresh-tooltip' className='tooltip-custom'>
        Refresh
      </Tooltip>
      <Tooltip id='long-answer-tooltip' className='tooltip-custom'>
        The question form will be textarea
      </Tooltip>
      <Tooltip id='hide-question-tooltip' className='tooltip-custom'>
        Hide question
      </Tooltip>

      <Tooltip id='task-attendance-tooltip' className='tooltip-custom'>
        The attendance system requires agents to take
        <br /> attendance when starting or ending work.
      </Tooltip>
      <Tooltip id='task-selfie-tooltip' className='tooltip-custom'>
        Agent must take a selfie <br /> during attendance.
      </Tooltip>
      <Tooltip id='task-clockout-tooltip' className='tooltip-custom'>
        Agent must perform the attendance
        <br /> process before completing work.
      </Tooltip>
    </>
  )
}

export default TooltipView
