import {defaultBreadcrumbs, ScreenProp} from 'src/app/models/screen-prop'

export const ServiceManagementScreen: Record<string, ScreenProp> = {
  SERVICE_MANAGEMENT: {
    PATH: '/master/service-management',
    TITLE: 'Service Management',
    TITLE_ALT: 'Service Management List',
    PERMISSION: 'service.read',
    breadcrumbs: defaultBreadcrumbs,
  },
  SERVICE_MANAGEMENT_ADD: {
    PATH: '/master/service-management/add',
    TITLE: 'Add Service Management',
    TITLE_ALT: 'Add Service Management',
    PERMISSION: 'service.create',
    breadcrumbs: defaultBreadcrumbs,
  },
  SERVICE_MANAGEMENT_EDIT: {
    PATH: '/master/service-management/edit/:id',
    TITLE: 'Edit Service Management ',
    TITLE_ALT: 'Edit Service Management',
    PERMISSION: 'service.update',
    breadcrumbs: defaultBreadcrumbs,
  },
}
