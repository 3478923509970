import {Order} from 'src/app/models/api.types'
import {IStatusData} from 'src/app/models/detail-admin.types'
import {IDepartment} from './Department.model'
import {IOrganization} from './Organization.model'
import {IRole} from './Role.model'

export type TUserType = 'SUPERADMIN' | 'ORGANIZATION' | 'DEPARTMENT' | 'USER'
export interface IUser extends IStatusData {
  id?: string
  first_name?: string
  last_name?: string
  email?: string
  access?: string
  organization_id?: string
  organization_access?: string
  is_active?: boolean
  role_id?: string
  department_id?: string
  mobile_number?: string
  is_superadmin?: boolean
  role?: IRole
  organization?: IOrganization
  department?: IDepartment
}

export interface IUserQuery {
  search?: string
  page?: number
  size?: number
  sort?: string
  order?: Order
  is_active?: boolean
  organization_ids?: string[]
  company_ids?: string[]
  department_ids?: string[]
}

export enum EUserType {
  SUPERADMIN = 'Superadmin',
  ORGANIZATION = 'Admin Organization',
  DEPARTMENT = 'Admin Department',
  USER = 'Configurable User',
}
