import {FC} from 'react'
import {cn} from 'src/app/utils/cn-utils'
import GIcon from '../Icon/GIcon'
import {alertTheme} from './Alert.theme'
import {GAlertIcon, GAlertProps} from './Alert.types'

export const GAlert: FC<GAlertProps> = ({
  children,
  colors = 'danger',
  icon,
  className,
  textClassname = '',
  iconClassname = '',
}) => {
  return (
    <div data-testid='galert-test' className={cn(alertTheme({colors, className}))}>
      <div className={cn('flex items-center justify-center w-5 h-5 mr-3', iconClassname)}>
        <GIcon
          data-testid='galert-test-icon'
          icon={icon ?? GAlertIcon[colors ?? 'danger']}
          className='flex items-center justify-center w-full h-full'
        />
      </div>
      <div className={cn('text-fs-9', textClassname)}>{children}</div>
    </div>
  )
}
