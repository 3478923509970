import {cva} from 'class-variance-authority'

export const chipTheme = cva(['py-[2px]', 'px-2', 'rounded', 'font-medium', 'text-fs-10'], {
  variants: {
    variant: {
      primary: ['bg-primary-50', 'text-primary-700'],
      success: ['bg-success-50', 'text-success-700'],
      danger: ['bg-danger-50', 'text-danger-700'],
      warning: ['bg-warning-50', 'text-warning-700'],
      info: ['bg-info-50', 'text-info-700'],
      neutral: ['bg-neutral-100', 'text-neutral-600'],
    },
  },
  compoundVariants: [
    {
      variant: 'success',
    },
  ],
  defaultVariants: {
    variant: 'success',
  },
})
