import {cva} from 'class-variance-authority'

export const iconButton = cva(
  ['outline-0', 'border-0', 'p-0', 'w-8', 'h-8', 'border-none', 'rounded'],
  {
    variants: {
      colors: {
        primary: [
          'bg-primary-50',
          'text-primary-500',
          'hover:bg-primary-100',
          'disabled:opacity-40',
        ],
        success: [
          'bg-success-50',
          'text-success-500',
          'hover:bg-success-100',
          'disabled:opacity-40',
        ],
        danger: ['bg-danger-50', 'text-danger-500', 'hover:bg-danger-100', 'disabled:opacity-40'],
        warning: [
          'bg-warning-50',
          'text-warning-500',
          'hover:bg-warning-100',
          'disabled:opacity-40',
        ],
        info: ['bg-info-50', 'text-info-500', 'hover:bg-info-100', 'disabled:opacity-40'],
        neutral: [
          'bg-transparent',
          'text-neutral-500',
          'hover:bg-neutral-100',
          'disabled:opacity-40',
        ],
      },
    },
    compoundVariants: [
      {
        colors: 'neutral',
      },
    ],
    defaultVariants: {
      colors: 'neutral',
    },
  }
)
