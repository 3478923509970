import React from 'react'
import {Card} from 'src/app/components/Libs'

const GuestWrapper: React.FC<{withTitle?: boolean; children?: React.ReactNode}> = ({
  withTitle = true,
  children,
}) => {
  return (
    <div className='flex items-center justify-center w-screen h-screen py-24 bg-neutral-50'>
      <div className='h-max-[688px] w-[520px]'>
        <Card.Root className='flex justify-between h-full p-[60px] rounded-lg'>
          <div className=''>{children}</div>
        </Card.Root>
      </div>
    </div>
  )
}

export default GuestWrapper
