import {defaultBreadcrumbs} from 'src/app/models/screen-prop'

export const IncomingScreen = {
  INCOMING: {
    PATH: '/wo-request/incoming',
    TITLE: 'Incoming',
    TITLE_ALT: 'Incoming List',
    breadcrumbs: defaultBreadcrumbs,
  },
  INCOMING_ADD: {
    PATH: '/wo-request/incoming/add',
    TITLE: 'Add Incoming',
    TITLE_ALT: 'Add Incoming',
    breadcrumbs: defaultBreadcrumbs,
  },
  INCOMING_EDIT: {
    PATH: '/wo-request/incoming/edit/:id',
    TITLE: 'Edit Incoming ',
    TITLE_ALT: 'Edit Incoming',
    breadcrumbs: defaultBreadcrumbs,
  },
  INCOMING_DETAIL: {
    PATH: '/wo-request/incoming/detail/:id',
    TITLE: 'Detail Incoming',
    TITLE_ALT: 'Detail Incoming',
    breadcrumbs: defaultBreadcrumbs,
  },
  INCOMING_HISTORY: {
    PATH: '/wo-request/incoming/history',
    TITLE: 'Incoming History',
    TITLE_ALT: 'Incoming History',
    breadcrumbs: defaultBreadcrumbs,
  },
}
