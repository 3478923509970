import {ScreenProp} from 'src/app/models/screen-prop'
import {TaskListScreen} from './page/task-list/Screen'

const TaskManagementScreens: Record<string, ScreenProp> = {
  TASK_MANAGEMENT: {
    PATH: '/task-management',
    TITLE: 'Task Management',
    TITLE_ALT: 'Task Management',
  },

  ...TaskListScreen,
}

export default TaskManagementScreens
